#root {
    overflow-x: hidden;
}

.ui-background {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--color-background);
    overflow-x: hidden;
}

.metal-background {
    background-image: linear-gradient(180deg, var(--neutral-1) 0%,rgba(0,0,0,0.75) 100%),url(https://foundrydigital.com/wp-content/uploads/Dot-Pattern-gradient-e1699561060462.png)!important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0 10%;
    overflow-x: hidden;
}

.form-input-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.animate-collapsible {
    --ease: cubic-bezier(0.87, 0, 0.13, 1);
    transition: max-height .5s var(--ease);
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    border-radius: var(--radius-l);
    width: 100%;
    display: grid;
    position: relative;
}

.collapsible {
    max-height: 10rem;
    overflow: hidden;
}

.open-collapsible {
    max-height: 75vh;
    overflow-x: auto;
}

.table {
    overflow: visible;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-l);
}

.table-header {
    position: sticky;
    top: -1px;
}

.click-to-expand {
    position: absolute;
    z-index: 2;
    transform: translate(-50%,-50%);
    left: 50%;
    bottom: 0;
    transition: opacity 1s ease-in-out;
}

.click-to-collapse {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: 1rem;
    width: fit-content;
    transition: opacity .5s ease-in-out;
}

.up-arrow {
    margin: 0 var(--space-s) 0 0;
    transition: transform .2s ease-in-out;
}

.down-arrow {
    margin: 0 0 0 var(--space-s);
    transition: transform .2s ease-in-out;
}

.gradient-on, .gradient-off {
    position: absolute;
    width: 100%;
    height: 10rem;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity .6s ease-in-out, max-height .6s ease-in-out;
    background: linear-gradient(0deg, rgba(23,25,24,1) 0%, rgba(23,25,24,0) 100%);
}

.gradient-on {
    opacity: 1;
    cursor: pointer;
    max-height: 10rem;
}

.gradient-off {
    opacity: 0;
    max-height: 0;
}

.sort-arrow {
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    width: 15px;
    height: 15px;
    margin: 0 0 0 var(--space-s);
}

.sort-label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.datepicker-warning:hover {
    color: var(--color-text);
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
}

.results-box {
    padding: var(--space-default) var(--space-xxl);
}

.secret-sauce-equations-grid-mobile {
    width: 100%;
}

.results-grid {
    margin: var(--space-xl) 0 0 0;
    display: grid;
    grid-template-columns: 15rem 1fr;
    align-items: center;
    margin: var(--space-m) var(--space-xl) 0 var(--space-xl);
}

.calc-brkdn-ttls-container-mobile {
    margin: var(--space-xl) 0 0 0;
}

.calc-ttls-label {
    max-width: 10ch;
}

.calc-ttls-value {
    text-align: left;
    min-width: 9ch;
}

.results-flex {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    border-bottom: 1px dotted white;
}

.card-rows {
    display: flex;
    padding: var(--space-s) 0;
    box-shadow: 0px 1px 0px var(--color-border);
}

.card-rows:last-of-type {
    box-shadow: none;
}

.mobile-card {
    margin: var(--space-m) 0;
}

.results-title {
    flex: 0 0 22%;
    margin: var(--space-l) 0;
    text-align: left;
}

.results-value {
    text-align: left;
    flex: 0 0 78%;
}

.results-equation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: var(--space-xl)
}

.division-bar {
    margin: var(--space-s);
    background-color: var(--color-text-muted);
    width: calc(var(--space-xxl) * 3);
    height: 1px;
}

.results-box-mobile {
    width: 100%;
}

.results-box-mobile > .results-equation {
    padding: var(--space-s) 0;
    margin-left: 0rem;
}

.results-equation-vertical {
    flex-direction: column;
}

.results-box-mobile > .results-equation > .multiply-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.results-title-mobile {
    flex-grow: 1;
    flex-shrink: 0;
}

.results-equals-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: var(--space-xs) 0 0 0;
    justify-content: center;
}

.totals {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: var(--space-xl);
    justify-content: space-between;
}

.totals > p:first-child {
    margin: var(--space-l) 0;
    text-align: left;
}

.totals > p:nth-child(2), .totals > p:nth-child(3) {
    text-align: left;
    min-width: calc(10ch + var(--space-xl) + var(--space-xl));
    padding-left: var(--space-xl);
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.errorAnime {
    animation: wiggle .3s ease-in-out;
}

.mobile-info:hover {
    text-decoration: none;
}

@keyframes wiggle {
    0% { transform: translate(1px, 0);}
    25% { transform: translate(-5px, 0);}
    50% { transform: translate(7px, 0);}
    75% { transform: translate(-3px, 0);}
    100% { transform: translate(1px, 0);}
}